import React, { useState, useContext, useEffect } from 'react';
import MenuBar from '../components/MenuBar';
import BarChart from '../components/BarChart';
import LineChart from '../components/LineChart';
import { AuthContext } from '../contexts/AuthContext';
import { Container, Grid, Box, Typography, Tabs, Tab, Breadcrumbs, Link } from '@mui/material';
import withLicenseCheck from '../components/LicenseCheck';
import LicensesModel from '../models/LicensesModel';
import { useNavigate } from 'react-router-dom';
import { UmiImages, fetchAllUmiImages } from '../network/prediction';
import OverlaySpinner from '../components/OverlaySpinner';
import Temp3mo from '../assets/Temperature_3mo.png';
import Temp6mo from '../assets/Temperature_6mo.png';
import Precip3mo from '../assets/Precipitation_3mo.png';
import Precip6mo from '../assets/Precipitation_6mo.png';

interface UmiProps {
  license: LicensesModel;
}

const Umi: React.FC<UmiProps> = ({ license }) => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [images, setImages] = useState<UmiImages | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState<string | null>(null);

  const sendEmail = async (to: string[], subject: string, message: string) => {
    try {
      const data = { to, subject, message };

      const response = await fetch('https://qr2wg9frib.execute-api.us-east-2.amazonaws.com/prod/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
      } else {
        console.error('Error:', result);
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending email');
    }
  };

  const handleUpgrade = () => {
    const recipientEmail = ['kalai@planette.ai', 'info@planette.ai', 'sales@planette.ai'];
    const emailSubject = 'Someone wants to upgrade Umi!';
    const emailMessage = `User wants to upgrade Umi license. Please contact them at ${user?.email} .`;

    sendEmail(recipientEmail, emailSubject, emailMessage);
    navigate('/upgrade');
  };

  const loadImages = async () => {
    if (images) return; // Don't reload if already loaded
    
    try {
      setIsLoading(true);
      const data = await fetchAllUmiImages();
      setImages(data);
    } catch (err) {
      console.error('Error loading images:', err);
      setLoadError(err instanceof Error ? err.message : 'Failed to load images');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadImages();

    return () => {
      if (images) {
        // Cleanup all object URLs
        Object.values(images.temp).forEach(url => URL.revokeObjectURL(url));
        Object.values(images.precip).forEach(url => URL.revokeObjectURL(url));
      }
    };
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className=" flex flex-col md:flex-row h-screen">
      <MenuBar />
      <div className="dashboard-container flex-1 p-4 bg-gray-100 transparent-background space-y-4 overflow-y-auto">
        <Container>
          <Breadcrumbs
            aria-label="breadcrumb"
            className="avenir-font"
            style={{ marginTop: '40px', marginBottom: '16px' }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">
              Umi<sup>TM</sup>
            </Typography>
          </Breadcrumbs>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Box>
              <h4 className="avenir-font title" style={{ color: '#1F5014', marginBottom: '10px' }}>
                Umi<sup>TM</sup> Dashboard
              </h4>
              <h4 className="avenir-font font-bold" style={{ color: 'gray', marginBottom: '10px' }}>
                Updated November 2024
              </h4>
            </Box>

            <Box>
              <button
                onClick={handleUpgrade}
                style={{
                  backgroundColor: '#1F5014',
                  width: '100%',
                  color: '#ffffff',
                }}
                className="p-2 border rounded w-42 avenir-font"
              >
                Subscribe
              </button>
              <h4
                className="avenir-font font-italic"
                style={{ color: 'gray', marginBottom: '10px', marginTop: '10px' }}
              >
                Expires: {new Date(license.expiry).toLocaleDateString()}
              </h4>
            </Box>
          </Box>

          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="chart tabs">
            <Tab label="Probabilities" />
            <Tab label="Temperature Outlook" />
            <Tab label="Precipitation Outlook" />
            <Tab label="Niño 3.4 Index Forecast" />
          </Tabs>

          <Box height="100%" width="100%" display="flex" flexDirection="column" mt={2}>
            {selectedTab === 0 && (
              <Box height="100%" width="100%" display="flex" flexDirection="column">
                <h4 className="avenir-font" style={{ color: 'black', marginTop: '10px', marginBottom: '20px' }}>
                  Monthly probabilities of El Niño, La Niña, or neutral conditions over the next six months from Umi™.
                  El Niño conditions are expected if the Niño 3.4 index is greater than 0.5, while La Niña conditions
                  are expected if the index is less than -0.5. Neutral conditions are expected when the Niño 3.4 index
                  is between 0.5 and -0.5.
                </h4>
                <BarChart />
              </Box>
            )}

            {selectedTab === 1 && (
              <Box height="100%" width="100%" display="flex" flexDirection="column">
                <Grid container spacing={2} mt={2}>
                  <h4 className="avenir-font" style={{ color: 'black', marginTop: '10px', marginBottom: '20px' }}>
                    Seasonal temperature outlooks over the next six months, based on the forecasted Niño 3.4 index 
                    from Umi™. Areas shaded in red are expected to experience higher than normal temperatures 
                    (relative to the last 5 years), while areas shaded in blue are expected to experience lower 
                    than normal temperatures.
                  </h4>
                  <Grid item xs={12} md={6}>
                    <img src={Temp3mo} alt="3-Month Temperature Outlook" style={{ width: '100%' }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <img src={Temp6mo} alt="6-Month Temperature Outlook" style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedTab === 2 && (
              <Box height="100%" width="100%" display="flex" flexDirection="column">
                <Grid container spacing={2} mt={2}>
                  <h4 className="avenir-font" style={{ color: 'black', marginTop: '10px', marginBottom: '20px' }}>
                    Seasonal precipitation outlooks over the next six months, based on the forecasted Niño 3.4 index
                    from Umi™. Areas shaded in green are expected to experience higher than normal precipitation
                    (relative to the last 5 years), while areas shaded in brown are expected to experience lower than
                    normal precipitation.
                  </h4>
                  <Grid item xs={12} md={6}>
                    <img src={Precip3mo} alt="3-Month Precipitation Outlook" style={{ width: '100%' }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <img src={Precip6mo} alt="6-Month Precipitation Outlook" style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedTab === 3 && (
              <Box height="100%" width="100%" display="flex" flexDirection="column">
                <h4 className="avenir-font" style={{ color: 'black', marginTop: '10px', marginBottom: '20px' }}>
                  The forecasted Niño 3.4 index from Umi™. The blue-green line shows the monthly forecasted values over
                  the next six months, while the grey line shows the historical Niño 3.4 index based on real-world
                  observations.
                </h4>
                <LineChart />
              </Box>
            )}
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default withLicenseCheck(Umi, 'UMI');
