import { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { getCurrentSession } from './auth';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import LocationPage from './pages/LocationPage2';
import Umi from './pages/Umi';
import Chrona from './pages/Chrona';
import Sura from './pages/SuraMapbox';
import EmailConfirmationPage from './pages/EmailConfirmationPage';
import SignupPage from './pages/SignupPage';
import PasswordReset from './pages/PasswordReset';
import './axiosConfig';
import { useAuth } from './hooks/useAuth';
import { AuthActions } from './constants/actionTypes';
import { getCurrentUserSession } from './network';
import OverlaySpinner from './components/OverlaySpinner';
import UserSignupError from './pages/UserSignupError';
import NoAccess from './pages/NoAccess';
import UpgradeRequest from './pages/UpgradeRequest';
import withLicenseCheck from './components/LicenseCheck';
import useUserAnalytics from './utils/UserAnalyticsUtils';
import PasswordResetConfirmation from './pages/PasswordResetConfirmation';
import UserSigninError from './pages/UserSigninError';
import UnderMaintenance from './pages/UnderMaintenance';

const UmiWithLicenseCheck = withLicenseCheck(Umi, 'UMI');

const App: React.FC = () => {
  const [currentSession, setCurrentSession] = useState<null | CognitoUserSession>(null);
  const [loading, setLoading] = useState(true);
  const { dispatch } = useAuth();
  useUserAnalytics();

  const getUserSession = useCallback(async () => {
    try {
      const userSession = await getCurrentSession();
      setCurrentSession(userSession);
      if (userSession) {
        const user = await getCurrentUserSession();
        dispatch({
          type: AuthActions.UPDATE,
          payload: { user },
        });
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getUserSession();
  }, [getUserSession]);

  if (loading) {
    return <OverlaySpinner />;
  }

  if (
    !currentSession &&
    !window.location.pathname.includes('/login') &&
    !window.location.pathname.includes('/signup')
  ) {
    window.location.replace('/login');
  }

  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/signup/emailconfirm" element={<EmailConfirmationPage />} />
        <Route path="/signup/error" element={<UserSignupError />} />
        {!currentSession && <Route path="/login" element={<LoginPage />} />}

        {/* Redirect from "/" and "/login" to "/home" */}
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/login" element={<Navigate to="/home" replace />} />

        <Route path="/login/resetpassword" element={<PasswordReset />} />
        <Route path="/login/resetpassword/confirm" element={<PasswordResetConfirmation />} />
        <Route path="/login/error" element={<UserSigninError />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/location" element={<LocationPage />} />
        <Route path="/umi" element={<UmiWithLicenseCheck />} />
        <Route path="/chrona" element={<Chrona />} />
        <Route path="/sura" element={<Sura />} />
        <Route path="/noaccess" element={<NoAccess />} />
        <Route path="/upgrade" element={<UpgradeRequest />} />
      </Routes>
    </Router>
  );
};

export default App;
