import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrgLicenses } from '../network/org';
import LicensesModel from '../models/LicensesModel';

interface LicenseProps {
  license: LicensesModel;
}

const withLicenseCheck = <P extends object>(
  WrappedComponent: React.ComponentType<P & LicenseProps>,
  requiredLicense: string,
) => {
  const ComponentWithLicenseCheck: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const [license, setLicense] = useState<LicensesModel | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      async function checkLicense() {
        const orgLicenses: LicensesModel[] = await getOrgLicenses();
        const foundLicense = orgLicenses.find((license) => license.name === requiredLicense && license.enabled);
        if (foundLicense) {
          setLicense(foundLicense);
        }
        setLoading(false);
      }
      checkLicense();
    }, []);

    useEffect(() => {
      if (!loading && !license) {
        navigate('/noaccess');
      }
    }, [loading, license, navigate]);

    if (loading) {
      return <div>Loading...</div>;
    }

    return license ? <WrappedComponent {...(props as P)} license={license} /> : null;
  };

  return ComponentWithLicenseCheck;
};

export default withLicenseCheck;
