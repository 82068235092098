import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Breadcrumbs, Link, Box, FormControl,Radio, RadioGroup, Checkbox,FormControlLabel, Select, MenuItem, Tabs, Tab } from '@mui/material';
import MenuBar from '../components/MenuBar';
import withLicenseCheck from '../components/LicenseCheck';
import LicensesModel from '../models/LicensesModel';
import mapboxgl, { AnySourceData, AnyLayer } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const mapBoxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

if (!mapBoxToken) {
  throw new Error("Mapbox access token is not defined in the environment variables");
}

mapboxgl.accessToken = mapBoxToken;

const TEMPERATURE_STYLE_ID = process.env.REACT_APP_TEMPERATURE_STYLE_ID;
const PRECIPITATION_STYLE_ID = process.env.REACT_APP_PRECIPITATION_STYLE_ID;
const USERNAME = process.env.REACT_APP_MAPBOX_USERNAME;

interface SuraProps {
  license: LicensesModel;
}

interface Layer {
  id: string;
  name: string;
}

interface LegendProps {
  tabValue: number; 
}

interface CustomLayer extends mapboxgl.Layer {
  source?: string;
}

interface CustomSource {
  type: string;
  url: string;
  tileSize: number;
}

type LayerWithSource = mapboxgl.AnyLayer & { source?: string };

const layerNameMapping: { [key: string]: string } = {
  'pr_1m': 'December 2024',
  'pr_2m': 'January 2025',
  'pr_3m': 'February 2025',
  'pr_1s': 'Mar-May 2025',
  't2m_1m': 'December 2024',
  't2m_2m': 'January 2025',
  't2m_3m': 'February 2025',
  't2m_1s': 'Mar-May 2025',
};
const LayerControl: React.FC<{
  layers: Layer[];
  activeLayer: string | null;
  onLayerChange: (id: string) => void;
}> = ({ layers, activeLayer, onLayerChange }) => {
  React.useEffect(() => {
    if (layers.length > 0 && !activeLayer) {
      onLayerChange(layers[layers.length - 1].id);
    }
  }, [layers, activeLayer, onLayerChange]);

  return (
    <div className="layer-control" style={{
      position: 'absolute',
      top: '10px',
      right: '10px',
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '4px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      zIndex: 1
    }}>
      <FormControl component="fieldset" sx={{ fontFamily: 'Avenir, sans-serif' }}>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Avenir, sans-serif' }}>Forecasts</Typography>
        <RadioGroup
          aria-label="layers"
          name="layers"
          value={activeLayer || ''}
          onChange={(e) => onLayerChange(e.target.value)}
        >
          {layers.map((layer) => (
            <FormControlLabel
              key={layer.id}
              value={layer.id}
              control={<Radio />}
              label={
                <Typography sx={{ fontFamily: 'Avenir, sans-serif' }}>
                  {layer.name}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};


const Sura: React.FC<SuraProps> = ({ license }) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [mapError, setMapError] = useState<string | null>(null);
  const [layers, setLayers] = useState<Layer[]>([]);
  const [activeLayer, setActiveLayer] = useState<string | null>(null);
  const popup = useRef<mapboxgl.Popup | null>(null);

  const Legend: React.FC<{ tabValue: number }> = ({ tabValue }) => {
    // Define the inverse RdYlBu color palette for temperature
    const temperatureColors = [
      "#4575b4", "#91bfdb", "#e0f3f8", "#ffffbf", "#fee090", "#fc8d59", "#d73027"];
  
    // Define the BrBG color palette for precipitation
    const precipitationColors = 
      ['#8c510a','#d8b365','#f6e8c3','#f5f5f5','#c7eae5','#5ab4ac','#01665e'];


  
    return (
      <div
        style={{
          position: 'absolute',
          bottom: '40px',
          right: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 1,
          width: '300px', // Adjust the width to avoid text wrapping
        }}
      >
        {/* Switch between temperature and precipitation legends based on tabValue */}
        {tabValue === 0 ? (
          <div>
            {/* Temperature Legend */}
            <div style={{ display: 'flex', height: '20px', marginBottom: '5px' }}>
              {temperatureColors.map((color, index) => (
                <div
                  key={index}
                  style={{
                    flex: 1,
                    backgroundColor: color,
                  }}
                />
              ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ color: 'black', fontSize: '12px' }}>Colder than normal</span>
              <span style={{ color: 'black', fontSize: '12px' }}>Warmer than normal</span>
            </div>
          </div>
        ) : (
          <div>
            {/* Precipitation Legend */}
            <div style={{ display: 'flex', height: '20px', marginBottom: '5px' }}>
              {precipitationColors.map((color, index) => (
                <div
                  key={index}
                  style={{
                    flex: 1,
                    backgroundColor: color,
                  }}
                />
              ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ color: 'black', fontSize: '12px' }}>Drier than normal</span>
              <span style={{ color: 'black', fontSize: '12px' }}>Wetter than normal</span>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  

  useEffect(() => {
    if (!mapContainer.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: {
        version: 8,
        sources: {
          'osm-tiles': {
            type: 'raster',
            tiles: ['https://api.mapbox.com/styles/v1/mapbox/navigation-night-v1/tiles/{z}/{x}/{y}?access_token='+mapboxgl.accessToken],
            tileSize: 256,
          }
        },
        layers: [{
          id: 'osm-tiles',
          type: 'raster',
          source: 'osm-tiles',
          minzoom: 0,
          maxzoom: 19,
        }]
      },
      center: [-74.5, 40],
      zoom: 3,
      minZoom: 3, 
      maxZoom: 10
    });

    map.current.addControl(new mapboxgl.NavigationControl(),  'top-left');

    popup.current = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    map.current.on('load', () => {
      console.log('Map has loaded successfully');
      updateMapStyle(tabValue);
      if (map.current) {
        map.current.on('mousemove', handleMapHover);
        map.current.on('mouseleave', handleMapLeave);
      }
    });
    map.current.on('mousemove', handleMapHover);
    map.current.on('mouseleave', handleMapLeave);

    map.current.on('error', (e: any) => {
      console.error('Mapbox GL error:', e);
      setMapError(`Mapbox GL error: ${e.error.message || 'Unknown error'}`);
    });

    return () => {
      if (map.current) map.current.remove();
    };
  }, []);

  useEffect(() => {
    if (map.current && map.current.loaded()) {
      updateMapStyle(tabValue);
    }
  }, [tabValue]);

  const updateMapStyle = (tabIndex: number) => {
    if (!map.current) return;

    const styleId = tabIndex === 0 ? TEMPERATURE_STYLE_ID : PRECIPITATION_STYLE_ID;
    const styleUrl = `mapbox://styles/${USERNAME}/${styleId}`;

    console.log(`Fetching style from: ${styleUrl}`);

    fetch(`https://api.mapbox.com/styles/v1/${USERNAME}/${styleId}?access_token=${mapboxgl.accessToken}`)
      .then(response => response.json())
      .then(customStyle => {
        console.log('Fetched custom style:', customStyle);

        // Remove previous custom layers and sources
        map.current!.getStyle().layers.forEach(layer => {
          if (!layer.id.includes('label') && layer.id !== 'osm-tiles') {  // Keep label layers
            map.current!.removeLayer(layer.id);
          }
        });
        Object.keys(map.current!.getStyle().sources).forEach(sourceId => {
          if (sourceId !== 'osm-tiles') {
            map.current!.removeSource(sourceId);
          }
        });

        Object.entries(customStyle.sources).forEach(([id, source]: [string, any]) => {
          if (!map.current!.getSource(id)) {
            map.current!.addSource(id, source);
          }
        });

        const newLayers: Layer[] = [];
        customStyle.layers.forEach((layer: any) => {
          if (layer.id !== 'osm-tiles') {
            map.current!.addLayer(layer);
            newLayers.push({
              id: layer.id,
              name: layerNameMapping[layer.id] || layer.id.replace(/-/g, ' ').replace(/\b\w/g, (l: string) => l.toUpperCase())
            });
          }
        });

        newLayers.reverse();

        setLayers(newLayers);

        if (newLayers.length > 0) {
          const topLayerId = newLayers[0].id;
          setActiveLayer(topLayerId);
          
          newLayers.forEach((layer) => {
            map.current!.setLayoutProperty(
              layer.id,
              'visibility',
              layer.id === topLayerId ? 'visible' : 'none'
            );
          });
        } else {
          setActiveLayer(null);
        }

        console.log('Updated map style');
      })
      .catch(error => {
        console.error('Error fetching style:', error);
        setMapError(`Error fetching style: ${error.message || 'Unknown error'}`);
      });
  };
 
  const handleLayerChange = (layerId: string) => {
    setActiveLayer(layerId);
    
    layers.forEach(layer => {
      map.current!.setLayoutProperty(
        layer.id,
        'visibility',
        layer.id === layerId ? 'visible' : 'none'
      );
    });
  };


  const handleMapHover = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (!map.current || !popup.current) {
      console.error('Map or popup is not initialized');
      return;
    }

    let features;
    try {
      features = map.current.queryRenderedFeatures(e.point);
    } catch (error) {
      console.error('Error querying rendered features:', error);
      return;
    }

    if (features.length > 0) {
      const hoveredFeature = features[0];
      const properties = hoveredFeature.properties;

      console.log(properties);

      if (properties) {
        let hoverText = '';
        const country = properties.country || 'N/A';
        const state = properties.state || 'N/A';
        const county = properties.region || 'N/A';

        switch (hoveredFeature.layer.id) {

          case 't2m_1m':
            const anom_t2m_1m = properties.anom_t2m_1m.toFixed(3);
            hoverText = `Anomaly: ${anom_t2m_1m}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
            break;
          
          case 't2m_2m':
            const anom_t2m_2m = properties.anom_t2m_2m.toFixed(3);
            hoverText = `Anomaly: ${anom_t2m_2m}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
            break;
          
          case 't2m_3m':
            const anom_t2m_3m = properties.anom_t2m_3m.toFixed(3);
            hoverText = `Anomaly: ${anom_t2m_3m}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
            break;
          
          case 't2m_1s':
            const anom_t2m_1s = properties.anom_t2m_1s.toFixed(3);
            hoverText = `Anomaly: ${anom_t2m_1s}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
            break;
          
          case 'pr_1m':
            const anom_pr_1m = properties.anom_pr_1m.toFixed(3);
            hoverText = `Anomaly: ${anom_pr_1m}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
            break;
          case 'pr_2m':
            const anom_pr_2m = properties.anom_pr_2m.toFixed(3);
            hoverText = `Anomaly: ${anom_pr_2m}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
            break;
          case 'pr_3m':
            const anom_pr_3m = properties.anom_pr_3m.toFixed(3);
            hoverText = `Anomaly: ${anom_pr_3m}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
            break;
          case 'pr_1s':
            const anom_pr_1s = properties.anom_pr_1s.toFixed(3);
            hoverText = `Anomaly: ${anom_pr_1s}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
            break;
          default:
            // Default hover text if no specific layer is active or recognized
            hoverText = 'No data available';
        }



        if (hoverText) {
          popup.current
            .setLngLat(e.lngLat)
            .setHTML(hoverText.replace(/\n/g, '<br>'))
            .addTo(map.current);
        } else {
          popup.current.remove();
        }
      } else {
        popup.current.remove();
      }
    } else {
      popup.current.remove();
    }
  };


  const handleMapLeave = () => {
    if (popup.current) {
      popup.current.remove();
    }
  };

  const handleToggleLayer = (layerId: string) => {
    setLayers(prevLayers => 
      prevLayers.map(layer => 
        layer.id === layerId 
          ? { ...layer } 
          : layer
      )
    );

    const visibility = map.current!.getLayoutProperty(layerId, 'visibility');
    map.current!.setLayoutProperty(
      layerId,
      'visibility',
      visibility === 'visible' ? 'none' : 'visible'
    );
  };

  const handleUpgrade = () => {
    navigate('/upgrade');
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className="flex h-screen">
      <MenuBar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-none p-4 bg-gray-100 transparent-background">
          <Breadcrumbs aria-label="breadcrumb" className="avenir-font mb-4">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">
              Sura<sup>TM</sup>
            </Typography>
          </Breadcrumbs>

          <div className="flex justify-between items-center mb-4">
            <div>
              <h4 className="avenir-font title text-[#1F5014] mb-2">
                Sura<sup>TM</sup> Dashboard 
              </h4>
              <h4 className="avenir-font font-bold text-gray-600">
                Updated: November 14, 2024
              </h4>
            </div>

            <div className="text-right">
              <button
                type="button"
                onClick={handleUpgrade}
                className="bg-[#1F5014] text-white p-2 rounded w-full avenir-font mb-2"
              >
                Subscribe
              </button>
              <h4 className="avenir-font italic text-gray-600">
                Expires: {new Date(license.expiry).toLocaleDateString()}
              </h4>
            </div>
          </div>

          <Tabs value={tabValue} onChange={handleTabChange} aria-label="map tabs">
            <Tab label="Temperature" />
            <Tab label="Precipitation" />
          </Tabs>
        </div>

        <div className="flex-1 relative bg-gray-100 transparent-background">
          <Box height="100%" width="100%" display="flex" flexDirection="column">
            <h4 className="avenir-font" style={{ color: 'black', marginTop: '10px', marginLeft: '10px', marginBottom: '20px' }}>
              {tabValue === 0 ? (
                "The map displays the forecast of temperature (in °C) for the indicated time period, expressed as the deviation from the usual (i.e. climatological) temperature for that region for that time of year. The climatological temperature is calculated from the period 2018 to 2023, so all the deviations should be interpreted relative to this baseline. Hover over different regions to view detailed anomaly information. "
              ) : (
                "The map displays the forecast of precipitation (in mm per day) for the indicated time period, expressed as the deviation from the usual (i.e. climatological) precipitation for that region for that time of year. The climatological precipitation is calculated from the period 2018 to 2023, so all the deviations should be interpreted relative to this baseline. Hover over different regions to view detailed anomaly information."  
              )}
            </h4>
            <div ref={mapContainer} style={{ width: '100%', height: '100%', position: 'relative' }}>
              <LayerControl 
                layers={layers} 
                activeLayer={activeLayer} 
                onLayerChange={handleLayerChange} 
              />
              <Legend tabValue={tabValue} />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default withLicenseCheck(Sura, 'SURA');
